<template>
  <div>
    <x-header>
      <img
        slot="headerImage"
        src="../../assets/huoyunzhuizong/banner.jpg"
        alt=""
      />
      <h1 slot="headerTitle">{{$store.state.navShow == true ? 'MG物流系统—专业国际物流定位系统':'Logistics cloud platform - digital management of international logistics innovation'}}</h1>
    </x-header>
    <div class="huoyun_main">
      <!-- <p>MG集团创新口岸通关流程，采用高效便捷的物流运输全业务流程系统化管理，可提供在途货物的优质跟踪服务，实现运踪实时查询。</p> -->
      <p style="margin-bottom: 0">
        {{$store.state.navShow == true ? '基于中欧班列运营操作业务而建立的动态的、连续的、集中的智慧型物流管理标准化协同作业平台。':'A dynamic, continuous and centralized intelligent logistics management standardized collaborative operation platform based on the China-Europe Railway Express operation business.'}}
      </p>
      <p>
        {{$store.state.navShow == true ? '系统涵盖业务、财务数据、单证操作各信息环节，通过业务数据进行定制化设计，智能化精准算法，全链条监管操作；平台以动态变化的视角分析和监管企业日常运作，构建以数据价值为中心的流动监管体系，解决企业数据集中作业以及数据管理需求，全面提升企业大数据管理能力。': "The system covers all information links of business, financial data and document operation and carries out customized design, intelligent and accurate algorithm through business data; The platform analyzes and supervises the daily operation of enterprises from the perspective of dynamic changes, constructs a mobile supervision system centered on data value to solves the needs of enterprise data centralized operation and data management and to comprehensively improves the ability of enterprise big data management."}}
      </p>
      <!-- 货运追踪查询 -->
      <div class="huoyun_query">
        <img :src="$store.state.navShow == true ? zhongjiant : zhongjiantE" alt="" />

        <div class="butTitle">{{$store.state.navShow == true ? '系统特点' : 'System Features'}}</div>

        <div class="butContent" data-aos="fade-right">
          <div
            class="butContent_mine"
            v-for="(item, index) in $store.state.navShow == true ?  butConList : butConListE"
            :key="index"
          >
            <img :src="item.image" alt="" />
            <div>{{ item.text }}</div>
          </div>
        </div>
        <!-- <img src="../../assets/huoyunzhuizong/huoyunzhuizongBg.jpg" alt="">
                <div class="query_range">

                    查询标题
                    <div class="queryTitle">
                        <i class="el-icon-search searchIcon"></i>
                        <h2 class="">货运追踪查询</h2>
                    </div>

                    <div class="querycontent">

                        <div class="queryKuang">
                            <div class="inputTextarea">
                                <el-form  label-width="80px">
                                    <el-form-item >
                                        <el-input v-model="textarea" placeholder="请填入箱号..." type="textarea" class="textArea"></el-input>
                                    </el-form-item>
                                </el-form>
                            </div>

                        立即查询按钮
                            <div class="nowQuery" @click="nowQueryClick">
                                <img src="../../assets/huoyunzhuizong/chaxunICON.png" alt="">
                                <p >立即查询</p>
                            </div>

                        </div>

                        <div class="queryLists">
                            <ul>
                                <li class="lists_title">跟踪区域</li>
                                <li v-for="(item,index) in li_itemsArr" :key="index">
                                    <div class="li_items" v-for="(item_content,item_index) in item" :key="item_index" :data-aos="index == 0 ? 'fade-right' : 'fade-left'">
                                        <img :src="item_content.item_Icon" alt="">
                                        <p>{{item_content.item_wenzi}}</p>
                                    </div>
                                    <div class="li_items">
                                        <img src="../../assets/huoyunzhuizong/eluoshi.png" alt="">
                                        <p>俄罗斯</p>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </div>

                </div> -->
      </div>
    </div>
    <img
      class="butimages"
      src="../../assets/huoyunzhuizong/ffadfs.png"
      alt=""
    />
  </div>
</template>
<script>
// 头尾导入
import xHeader from '../../components/header.vue'
export default {
  components: {
    xHeader
  },
  data () {
    return {
      zhongjiant: require('../../assets/huoyunzhuizong/zhongjiant.jpg'),
      zhongjiantE: require('../../assets/huoyunzhuizong/zhongjiantE.jpg'),
      // 多行文本
      textarea: '',
      butConList: [
        {
          image: require('../../assets/huoyunzhuizong/1.png'),
          text: '高效匹配板块断点'
        },
        {
          image: require('../../assets/huoyunzhuizong/2.png'),
          text: '智能算法避免人工失误'
        },
        {
          image: require('../../assets/huoyunzhuizong/3.png'),
          text: '强大技术团队系统功能全面'
        },
        {
          image: require('../../assets/huoyunzhuizong/4.png'),
          text: '数据安全保密保护客户隐私'
        }
      ],
      butConListE: [
        {
          image: require('../../assets/huoyunzhuizong/1.png'),
          text: 'Efficiently match plate breakpoints'
        },
        {
          image: require('../../assets/huoyunzhuizong/2.png'),
          text: 'Smart algorithms avoid human errors'
        },
        {
          image: require('../../assets/huoyunzhuizong/3.png'),
          text: 'Strong technical team with comprehensive system functions'
        },
        {
          image: require('../../assets/huoyunzhuizong/4.png'),
          text: 'Data security and confidentiality to protect customer privacy'
        }
      ],
      // 查询列表li每一项数组
      li_itemsArr: [
        [
          {
            item_Icon: require('../../assets/huoyunzhuizong/eluoshi.png'),
            item_wenzi: '俄罗斯'
          },
          {
            item_Icon: require('../../assets/huoyunzhuizong/hassakesitan.png'),
            item_wenzi: '哈萨克斯坦'
          }
        ],
        [
          {
            item_Icon: require('../../assets/huoyunzhuizong/mengguguo.png'),
            item_wenzi: '蒙古国'
          },
          {
            item_Icon: require('../../assets/huoyunzhuizong/jierjisi.png'),
            item_wenzi: '吉尔吉斯斯坦'
          }
        ],
        [
          {
            item_Icon: require('../../assets/huoyunzhuizong/wuzibieke.png'),
            item_wenzi: '乌兹别克斯坦'
          },
          {
            item_Icon: require('../../assets/huoyunzhuizong/baieluosi.png'),
            item_wenzi: '白俄罗斯'
          }
        ],
        [
          {
            item_Icon: require('../../assets/huoyunzhuizong/bolan.png'),
            item_wenzi: '波兰'
          },
          {
            item_Icon: require('../../assets/huoyunzhuizong/deguo.png'),
            item_wenzi: '德国'
          }
        ]
        // {
        //     item_Icon : require('../../assets/huoyunzhuizong/eluoshi.png'),
        //     item_wenzi:'俄罗斯33'
        // },
        // {
        //     item_Icon : require('../../assets/huoyunzhuizong/eluoshi.png'),
        //     item_wenzi:'俄罗斯44'
        // }
      ]
    }
  },
  methods: {
    /**
     * 立即查询按钮
     * 肖明霞
     * 2021-11-23
     */
    nowQueryClick () {
      // 判断输入的内容是否为空
      if (this.textarea === '') {
        this.$message({
          showClose: true,
          message: '请输入查询内容',
          type: 'warning'
        })
      } else {
        window.open(
          'http://demo.arkics.com/portal/orderSop/4360?jobNo=' + this.textarea,
          '_blank'
        )
      }
    }
  }
}
</script>
<style lang="less" scoped>
.butimages {
  width: 100%;
  height: 240px;
}
.huoyun_main {
  width: 1200px;
  // background: red;
  margin: 120px auto 90px;

  & > p {
    text-indent: 32px;
    text-align: left;
    margin: 0px 0 60px 0;
        text-indent: 32px;
    font-family: Microsoft YaHei;
    color: #666666;
    line-height: 35px;
  }
  // 货运追踪查询
  .huoyun_query {
    width: var(--width1200px);
    height: 578px;
    margin: 0 auto;
    // background: green;
    position: relative;
    .butTitle {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #2e67b1;
      text-align: left;
      margin-top: 64px;
      margin-bottom: 20px;
    }
    .butContent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .butContent_mine {
        width: 277px;
        background: #ffffff;
        box-shadow: 0px 0px 20px 0px rgba(41, 92, 169, 0.1);
        padding: 28px 0 32px 0;
        > img {
          margin-bottom: 24px;
        }
        > div {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #666666;
        }
      }
    }

    .query_range {
      width: 380px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0px 0px 20px 0px rgba(45, 99, 171, 0.1);
      // 标题
      .queryTitle {
        height: 68px;
        // background:pink;
        display: flex;
        text-align: left;
        align-items: center;
        color: #fff;
        font-family: Source Han Sans CN;
        padding-left: 20px;
        box-sizing: border-box;
        .searchIcon {
          font-size: 25px;
          font-weight: 900;
          margin: 0 15px 0 0;
        }
      }
      // 查询内容
      .querycontent {
        // 查询框
        padding: 0 17px;
        box-sizing: border-box;
        background: #fff;
        overflow: hidden;
        .queryKuang {
          width: 340px;
          height: 130px;
          border: 3px solid;
          border-image: linear-gradient(270deg, #3676c8, #42be72) 3 3;
          margin: 20.5px 0 8px 0;
          position: relative;
          // input textarea
          .inputTextarea {
            width: 100%;
            height: calc(100% - 44px);
            // background: orange;
            & .textArea ::-webkit-input-placeholder {
              color: #666;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
            }
          }
          // 立即查询
          .nowQuery {
            width: 100%;
            height: 44px;
            background: linear-gradient(-90deg, #3676c9, #42be72);
            position: absolute;
            bottom: 0;
            display: flex;
            align-items: center;
            color: #fff;
            justify-content: center;
            font-weight: 900;
            font-family: Source Han Sans CN;
            cursor: pointer;
            img {
              // transform: scale(0.8);
              margin-right: 10px;
              line-height: 44px;
            }
          }
        }
        // 查询列表
        .queryLists {
          width: 100%;
          // height: calc(100% - 130px - 68px);
          // background:red;
          & .lists_title {
            text-align: left;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #2e67b1;
          }
          & ul {
            & > li {
              height: 70px;
              // background: pink;
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-top: 1px solid #e0e0e0;
              padding-left: 5px;
              box-sizing: border-box;
              &:nth-child(1) {
                border-top: none;
                height: 65px;
              }
              .li_items {
                width: 50%;
                display: flex;
                align-items: center;
                text-align: left;
                // background: red;
                p {
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  color: #666666;
                  margin-left: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
/* 多行文本输入 */
.el-form-item__content {
  margin-left: 0 !important;
  // 输入时候的样式
  .el-textarea__inner {
    max-height: 86px !important;
    min-height: 86px !important;
    resize: none;
    // background:red;
    padding: 15px 18px;
    box-sizing: border-box;
    color: #666 !important;
    font-size: 14px !important;
    font-family: Microsoft YaHei !important;
    font-weight: 400 !important;
  }
}
</style>
